import React, { Component } from "react"
import Spinner from "./Spinner";

const sessionUrl = `/.netlify/functions/commerce/checkout-session`;

export default class CheckoutSuccess extends Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  componentDidMount() {

    // check for session ID
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session_id")

    // need session id to proceed
    if( sessionId ) {

      let request = new Request( `${sessionUrl}?sessionId=${sessionId}` );

      this.setState({
        'loadingSession': true,
      });

      fetch( request )
        .then(response => response.json())
        .then(data => {
          this.setState({
            'loadingSession': false,
            'paymentStatus': data.payment_status,
          });
        });
      
    } else {

    }


  }

  render()
  {

    return (
      <section>

        { this.state.loadingSession && <Spinner className="block w-5 mx-auto" /> }

        { this.state.paymentStatus === 'paid' &&
        <>
          <h2 className="mb-4 text-3xl font-bold text-center tracking-wide">Payment successful</h2>
    <p className="mb-4 text-center">An email will be sent shortly with your license and activation details.</p>
        </>
        }

      </section>
    )
  }

}